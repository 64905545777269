import React from 'react';

const ThemeBlock = ({ theme, changeTheme }) => {
  return (
    <div className='dark-box'>
      <button onClick={changeTheme} className='dark-mode'>
        <span className='pc-only'>
          {theme === 'dark' ? 'Light Mode ' : 'Dark Mode '}
        </span>
        <i className={theme === 'dark' ? 'fas fa-sun' : 'far fa-moon'}></i>
      </button>
    </div>
  );
};

export default ThemeBlock;
