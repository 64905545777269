import React from "react";

const Hero = ({ loading = true, onImageLoad, offsetY = 0 }) => {
  return (
    <div className={`hero ${loading ? "" : "hero-visible"}`}>
      <div className='main-head'>
        <div
          style={
            offsetY < window.innerHeight + 100
              ? {
                  transform: `translateY(${
                    window.innerWidth > 500 ? offsetY * -0.24 : offsetY * -0.2
                  }px) scale(${1 - offsetY * 0.0009})`,
                }
              : {}
          }
        >
          <h1 className='main-heading'>THINKER</h1>
        </div>
        <div
          style={
            offsetY < window.innerHeight + 100
              ? {
                  transform: `translateY(${
                    window.innerWidth > 500 ? offsetY * -0.44 : offsetY * -0.38
                  }px) scale(${1 - offsetY * 0.0006})`,
                }
              : {}
          }
        >
          <h1 className='main-heading2'>DESIGNER</h1>
        </div>
        <div
          style={
            offsetY < window.innerHeight + 100
              ? {
                  transform: `translateY(${
                    window.innerWidth > 500 ? offsetY * -0.6 : offsetY * -0.52
                  }px) scale(${1 - offsetY * 0.0003})`,
                }
              : {}
          }
        >
          <h1 className='main-heading3'>DEVELOPER</h1>
        </div>
      </div>

      <div
        className='main-img'
        style={
          window.innerWidth > 768 && offsetY < window.innerHeight + 350
            ? { width: `calc(50% + ${offsetY * 0.05}px)` }
            : {}
        }
      >
        <img
          className='hero-img'
          style={
            offsetY < window.innerHeight + 350
              ? {
                  transform: `translateY(${
                    window.innerWidth > 500 ? offsetY * 0.1 : offsetY * 0.12
                  }px)`,
                }
              : {}
          }
          src='/img/mocky.jpg'
          // src='/img/my-pic.jpg'
          alt='Hero'
          width='100%'
          onLoad={() => onImageLoad?.()}
        />
      </div>
    </div>
  );
};

export default Hero;
