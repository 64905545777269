import React, { useState, useEffect, useRef } from "react";

const Intro = ({ theme }) => {
  const [appear, setAppear] = useState(false);
  const introRef = useRef();

  const handleScroll = () => {
    var top = window.pageYOffset;

    var introOffset;
    if (window.innerWidth < 450)
      introOffset = introRef.current.offsetTop - window.innerHeight + 320;
    else introOffset = introRef.current.offsetTop - window.innerHeight + 380;

    if (top >= introOffset) setAppear(true);
    else setAppear(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div ref={introRef} className={`intro intro-${theme}`}>
      <div className='row'>
        <div className='col-md-4'>
          <div className='textbox'>
            <h4 className={appear ? "" : "hide-heading"}>WELCOME</h4>
          </div>
        </div>
        <div className='col-md-8'>
          <div className='textbox'>
            <p className={appear ? "" : "hide"}>
              I'm Muhammad Aqib Asif, Designer and Full stack Developer based in
              Pakistan. I work as a freelancer with agencies, start-ups and
              individuals. I have a fondness for interaction design, beautiful
              animations and creative development.
              <br />
              <br />
              Currently : I am available to work on creative projects.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
