import React from "react";

const Navbar = ({ loading = true, offsetY = 0 }) => {
  return (
    <div className={`header ${!loading ? "visible-header" : ""}`}>
      <div style={{ transform: `translateY(${offsetY * -0.4}px)` }}>
        <p>MUHAMMAD AQIB ASIF</p>
      </div>
      <div style={{ transform: `translateY(${offsetY * -0.5}px)` }}>
        <p className='pc-only'>TEMPORARY PORTFOLIO OF</p>
      </div>
      <div style={{ transform: `translateY(${offsetY * -0.4}px)` }}>
        <p className='pc-only'>AQIBASIF48@GMAIL.COM</p>
      </div>
    </div>
  );
};

export default Navbar;
