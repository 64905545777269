import React, { useState, useEffect, useRef } from 'react';

const Services = () => {
  const [appear, setAppear] = useState(false);
  const servicesRef = useRef();

  const handleScroll = () => {
    var top = window.pageYOffset;

    var introOffset;
    if (window.innerWidth < 450)
      introOffset = servicesRef.current.offsetTop - window.innerHeight + 320;
    else introOffset = servicesRef.current.offsetTop - window.innerHeight + 380;

    if (top >= introOffset) setAppear(true);
    else setAppear(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={servicesRef} className='intro'>
      <div className='row'>
        <div className='col-md-4'>
          <div className='textbox'>
            <h4 className={appear ? '' : 'hide-heading'}>SERVICES</h4>
          </div>
        </div>
        <div className='col-md-8'>
          <div className='textbox'>
            <p className={appear ? '' : 'hide'}>
              I’m working as a creative designer and full stack developer across UX/UI
              Design, Websites, IOS Andriod apps and I am constantly
              exploring new opportunities. My clients range from small startups
              and independents to large global brands. Take a look at my work
              overview!
              <br />
              <br />
              Currently I'm working in following technologies.
            </p>

            <br />
          </div>
          <div className='textbox'>
            <p className={appear ? '' : 'hide-heading'}>
              <i className='fab fa-react'></i>
              <i className='fab fa-node'></i>
              <i className='fab fa-apple'></i>
              <i className='fab fa-android'></i>
              <i className='fab fa-js-square'></i>
              <i className='fab fa-wordpress'></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
