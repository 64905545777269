import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import Hero from "./hero";
import Intro from "./intro";
import Services from "./services";
import MyProjects from "./myProjects";
import Footer from "./footer";
import "./stylesheet.css";
import "bootstrap/dist/css/bootstrap.css";
import ThemeBlock from "./themeBlock";
import Loader from "./loader";
import useWindowOffsetY from "../Hooks/useWindowOffsetY";
import FontFaceObserver from "fontfaceobserver";

const Application = () => {
  const offsetY = useWindowOffsetY();

  const [theme, setTheme] = useState("light");
  const [loading, setLoading] = useState(true);
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    loadFonts();

    window.scrollTo(0, 0);

    setLoading(true);
    const html = document.getElementsByTagName("html")?.[0];
    if (html) html.style.overflowY = "hidden";
  }, []);

  useEffect(() => {
    if (!heroImageLoaded || !fontsLoaded) return;

    const html = document.getElementsByTagName("html")?.[0];

    setTimeout(() => {
      setLoading(false);
      if (html) html.style.overflowY = "auto";
    }, 1800);
  }, [heroImageLoaded, fontsLoaded]);

  const loadFonts = async () => {
    const fontFamilies = {
      "Grotesk 300": { weight: 300 },
      "Grotesk 400": { weight: 400 },
      "Grotesk 500": { weight: 500 },
      "Grotesk 600": { weight: 600 },
    };

    const observers = [];
    let loaded = 0;

    Object.keys(fontFamilies).forEach(function (family) {
      const data = fontFamilies[family];
      const obs = new FontFaceObserver(family.split(" ")?.[0], data);
      observers.push(obs.load());
    });

    Promise.all(observers)
      .then(function (fonts) {
        fonts.forEach(function (font) {
          console.log(`${font.family} ${font.weight} loaded`);
          ++loaded;
          if (loaded === Object.keys(fontFamilies).length) {
            setFontsLoaded(true);
          }
        });
      })
      .catch(function (err) {
        console.warn("Some critical font are not available:", err);
        setFontsLoaded(true);
      });
  };

  const changeTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return !fontsLoaded ? (
    <></>
  ) : (
    <div className={theme}>
      <Loader loading={loading} />
      <Navbar loading={loading} offsetY={offsetY} />
      <Hero
        loading={loading}
        onImageLoad={() => setHeroImageLoaded(true)}
        offsetY={offsetY}
      />

      {loading ? (
        <></>
      ) : (
        <>
          <ThemeBlock theme={theme} changeTheme={changeTheme} />
          <Intro theme={theme} />
          <Services />
          <MyProjects />
          {/* Load the images of these projects on start as well */}
          <Footer theme={theme} />
        </>
      )}
    </div>
  );
};

export default Application;
