import React, { useEffect, useState } from "react";

const Loader = ({ loading = true }) => {
  const [hideLoader, setHideLoader] = useState(false);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setHideLoader(true);
      }, 1200);
    }
  }, [loading]);

  return hideLoader ? (
    <></>
  ) : (
    <div
      className={`new-loader-container ${
        !loading ? "new-reverse-animate" : ""
      }`}
    >
      <div className='new-loader-inner-container'>
        <h1>Muhammad Aqib Asif</h1>
      </div>
    </div>
  );
};

export default Loader;
