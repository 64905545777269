import React, { useState, useEffect, useRef } from "react";
import detect from "detect.js";

function getWidth() {
  const { innerWidth: width } = window;
  return width;
}

const descriptionBlock = (title, description, techs, link, format, width) => {
  return (
    <div className='col-md-6'>
      <div
        className='project-details'
        style={format === "2" && width > 768 ? { textAlign: "right" } : {}}
      >
        <h2 className='project-title'>{title}</h2>

        <p className='project-description'>{description}</p>
        <p className='project-description'>
          <span>Technologies used: </span>
          {techs}
        </p>
        {!!link && (
          <p>
            <a
              href={link}
              target='_blank'
              rel='noopener noreferrer'
              className='project-link'
            >
              Link to visit
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

const imageBlock = (id, img, offsetY) => {
  return (
    <div className='col-md-6'>
      <div className='project-img-block'>
        <img
          style={{ transform: `translateY(${offsetY * 1.75}px)` }}
          src={img}
          alt='img'
          className={"project-img"}
        />
        <h1 className='p-head'>{id}</h1>
      </div>
    </div>
  );
};

const Project = ({ id, title, description, techs, img, link, format }) => {
  const [width, setWidth] = useState(getWidth());
  const [offsetY, setOffsetY] = useState(0);
  const projectRef = useRef();

  useEffect(() => {
    const handleResize = () => setWidth(getWidth());

    const handleScroll = () => {
      var top = window.pageYOffset;
      const height = window.innerHeight;
      const offSetTop = projectRef.current.offsetTop;

      const user = detect.parse(navigator.userAgent);
      const isSafari = user.browser.family.includes("Safari");

      // handle scrolling for large screens

      var projectOff;
      //  TODO: make sure image is in center of screen
      if (getWidth() < 768) projectOff = offSetTop - height;
      else projectOff = offSetTop - height;

      //   projectOff = offSetTop - height + (isSafari ? 0 : 250);
      // else projectOff = offSetTop - height + 150 + (isSafari ? 0 : 200);

      if (top >= projectOff) {
        const divident =
          getWidth() < 768 ? (isSafari ? 3 : 3.5) : isSafari ? 1.75 : 2;
        // var val = top - projectOff - height / (getWidth() < 768 ? 3 : 2);
        var val = top - projectOff - height / divident;
        var movingval = -0.3 * val;
        // var movingval = -0.3 * val;

        setOffsetY(movingval);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return format === "1" && width > 768 ? (
    <div ref={projectRef} className='row project'>
      {descriptionBlock(title, description, techs, link)}
      {imageBlock(id, img, offsetY)}
    </div>
  ) : (
    <div ref={projectRef} className='row project'>
      {imageBlock(id, img, offsetY)}
      {descriptionBlock(title, description, techs, link, format, width)}
    </div>
  );
};

export default Project;
