import React, { useState, useEffect, useRef } from "react";
import Project from "./project";

const projects = [
  {
    title: "DESIGNER'S PORTFOLIO",
    description: "Portfolio of a Pakistani UI/UX Designer",
    techs: "ReactJS",
    link: "https://humnaasif.vercel.app",
    img: "/img/019.png",
  },
  {
    title: "Beverix - E Commerce Store",
    description: "Beverage selling online store",
    techs: "ReactJS, NodeJS, MongoDB",
    link: "https://beverix.vercel.app",
    img: "/img/010.png",
  },
  {
    title: "FRATELLI - E Commerce Store",
    description: "Turkish Figs and Chocolates",
    techs: "ReactJS, NodeJs, MongoDB",
    link: "https://fratelli.vercel.app",
    img: "/img/018.png",
  },
  {
    title: "ARCHITECT'S PORTFOLIO",
    description: "Portfolio of a Swiss Architect",
    techs: "ReactJS",
    link: "https://derhaus.ch",
    img: "/img/020.png",
  },
  {
    title: "MESSIX - WEBSITE",
    description: "Basic messaging website",
    techs: "ReactJS, NodeJs, MongoDB",
    link: "https://messix.vercel.app",
    img: "/img/014.png",
  },
  {
    title: "Beverix - Native App",
    description: "Beverage selling app for IOS and Android",
    techs: "React Native, NodeJs, MongoDB",
    // link: "https://expo.io/@aqibasif/projects/Beverix?release-channel=staging",
    img: "/img/004.png",
  },
  {
    title: "Omega - website",
    description: "Sanitary fittings",
    techs: "ReactJS",
    link: "https://omegasanitary.vercel.app",
    img: "/img/007.png",
  },
  {
    title: "Personal Portfolio",
    description: "My old portfolio",
    techs: "ReactJS",
    // link: "https://aqibasif.herokuapp.com",
    img: "/img/006.png",
  },
  {
    title: "VERk - e commerce store",
    description: "E-commerce store for a Swedish watches brand",
    techs: "ReactJS, NodeJS, MongoDB",
    // link: "https://verk-store.herokuapp.com",
    img: "/img/002.png",
  },
];

const MyProjects = () => {
  const [appear, setAppear] = useState(false);
  const projectsRef = useRef();

  const handleScroll = () => {
    var top = window.pageYOffset;

    var myProjectsOffset;
    if (window.innerWidth < 450)
      myProjectsOffset =
        projectsRef.current.offsetTop - window.innerHeight + 320;
    else
      myProjectsOffset =
        projectsRef.current.offsetTop - window.innerHeight + 380;

    if (top >= myProjectsOffset) setAppear(true);
    else setAppear(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div ref={projectsRef} className='projects'>
      <div className='projects-inner-div'>
        <div className='textbox'>
          <h1 className={`myprojects ${appear ? "" : "hide-heading"}`}>
            MY PROJECTS
          </h1>
        </div>
        <div className='space' />

        <br />
        <br />

        {projects.map((p, idx) => {
          return (
            <Project
              key={idx}
              id={"0" + (idx + 1)}
              title={p.title}
              description={p.description}
              techs={p.techs}
              link={p.link}
              img={p.img}
              format={idx % 2 === 0 ? "1" : "2"}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MyProjects;
